import React from "react";
import {
  InsightIcon,
  LogOutIcon,
  MyMusicIcon,
  SettingsIcon,
  WalletIcon,
} from "../Icons/Icons";
import MenuItems from "../MenuList/MenuItems";
import UpgradeBanner from "../UpgradeBanner/UpgradeBanner";
import RoyaltySplitIcon from "../Icons/RoyaltySplitIcon";
import DashLogo from "../../assets/dashlogo.png";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import sendRequest from "../axios";
import PlaylistIcon from "../../assets/playlist";
import AccessFundingIcon from "../../assets/access-funding";
import ArtistIcon from "../../assets/artist";
import { SideBarHandle } from "./HomeMobileSideBar";
import Xmark from "../Icons/Xmark";
import BarIcon from "../Icons/BarIcon";
import XBlackLogo from "../Icons/XBlackLogo";
import UserIcon from "../usericon";
// import DashLogo from "../Icons/DashLogo";
// import WhiteLogo from "../Icons/LandingLogo";
// import { Link } from "react-router-dom";
// import Button from "../Button/Button";

interface Item {
  icon?: React.ReactNode;
  text: string;
  to: string;
  comingsoon?: boolean;
}

interface Props {
  display?: string;
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

function SidebarIcon({ children }: { children: React.ReactNode }) {
  return <div className="size-7">{children}</div>;
}

function SideBar({ display, showSidebar, setShowSidebar }: Props) {
  const { user } = useDashboardContext();
  const items: Item[] = [
    {
      icon: (
        <SidebarIcon>
          <MyMusicIcon />
        </SidebarIcon>
      ),
      text: "My Music",
      to: "/dashboard/my-music",
    },
    {
      icon: (
        <SidebarIcon>
          <RoyaltySplitIcon />
        </SidebarIcon>
      ),
      text: "Royalty Splits",
      to: "/dashboard/royalty-splits",
    },
    {
      icon: (
        <SidebarIcon>
          <ArtistIcon />
        </SidebarIcon>
      ),
      text: "Artists",
      to: "/dashboard/artists",
    },
    {
      icon: (
        <SidebarIcon>
          <WalletIcon />
        </SidebarIcon>
      ),
      text: "Wallet",
      to: "/dashboard/wallet",
    },
    // {
    //   icon: (
    //     <SidebarIcon>
    //       <InsightIcon />
    //     </SidebarIcon>
    //   ),
    //   text: "Insights",
    //   comingsoon: true,
    //   to: "/dashboard/insight",
    // },
    // {
    //   icon: (
    //     <SidebarIcon>
    //       <AccessFundingIcon />
    //     </SidebarIcon>
    //   ),
    //   text: "Access Funding",
    //   comingsoon: true,
    //   to: "/dashboard/insight",
    // },
    {
      icon: (
        <SidebarIcon>
          <PlaylistIcon />
        </SidebarIcon>
      ),
      text: "Playlist Support",
      // comingsoon: true,
      to: "/dashboard/playlist-support",
    },
    {
      icon: (
        <SidebarIcon>
          <SettingsIcon />
        </SidebarIcon>
      ),
      text: "Settings",
      to: "/dashboard/settings",
    },
  ];

  const logout = () => {
    sendRequest("GET", "/api/v1/logout", {}).then((res) => {
      localStorage.removeItem("token");
      window.location.href = "/login";
    });
  };

  return (
    <div>
      <div className="hidden md:block lg:block">
        <aside
          className={`${display} bg-white absolute  w-[260px] h-screen flex flex-col justify-between`}
          aria-label="Sidebar">
          <div className="p-4 overflow-auto no-scrollbar space-y-2">
            <div className="space-y-2">
              <div className="flex items-center font-bold text-[27px] leading-8 text-xd_lightblack">
                <div className="">
                  <img className="" src={DashLogo} />
                </div>
              </div>
              {items.map((item, key) => (
                <div
                  key={key}
                  className="flex items-center hover:rounded-[10px]">
                  <MenuItems
                    to={item.comingsoon ? "#!" : item.to}
                    className=""
                    icon={item.icon}>
                    <span>
                      {item.text}{" "}
                      {item.comingsoon ? (
                        <span className="text-[0.4rem] text-xd_red">
                          Coming Soon
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </MenuItems>
                </div>
              ))}
            </div>

            <div className="pt-2 px-auto bg-white border-t">
              <div className="flex items-center font-normal">
                <MenuItems
                  onClick={logout}
                  className="rounded-[10px] items-center text-[14px] flex gap-2 leading-4"
                  icon={<LogOutIcon />}>
                  Log Out
                </MenuItems>
              </div>
            </div>

            <div className="py-2 top-shadow border-t">
              <UpgradeBanner />
            </div>
          </div>

          <div className="bg-white w-full flex gap-2 items-center text-base font-medium p-4">
            <div className="size-14">
              <UserIcon user={user}/>
            </div>
            <div className="items-center">
              <div className="text-lg">{user.first_name}</div>
              <div className="text-xs text-ellipsis">{user.email}</div>
            </div>
          </div>
        </aside>
      </div>
      <div className=" bg-white px-5 md:hidden block ">
        <div className="flex justify-between items-center">
          <div className="h-[64px] w-[100px]">
            <XBlackLogo />
          </div>
          <div>
            {showSidebar ? (
              <SideBarHandle onClick={() => setShowSidebar(!showSidebar)}>
                <Xmark strokeColor="black" />
              </SideBarHandle>
            ) : (
              <SideBarHandle onClick={() => setShowSidebar(!showSidebar)}>
                <BarIcon strokeColor="black" />
              </SideBarHandle>
            )}
          </div>
        </div>
        {showSidebar ? (
          <div className="absolute right-0 bg-white overflow-y-auto z-[150] max-h-dvh px-4">
            <div className="text-black font-semibold text-2xl mt-5">
              <div className="px-3">
                <div className="flex flex-col gap-2">
                  {items.map((item, key) => (
                    <div
                      key={key}
                      className="flex items-center hover:rounded-[10px]">
                      <MenuItems
                        to={item.comingsoon ? "#!" : item.to}
                        className=""
                        onClick={() => setShowSidebar(!showSidebar)}
                        icon={item.icon}>
                        <span>
                          {item.text}{" "}
                          {item.comingsoon ? (
                            <span className="text-[0.4rem] text-xd_red">
                              Coming Soon
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </MenuItems>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="pt-2 px-auto bg-white border-t">
              <div className="flex items-center font-normal">
                <MenuItems
                  onClick={logout}
                  className="rounded-[10px] items-center text-[14px] flex gap-2 leading-4"
                  icon={<LogOutIcon />}>
                  Log Out
                </MenuItems>
              </div>
            </div>
            <div className="py-2 top-shadow border-t">
              <UpgradeBanner />
            </div>
            <div className="bg-white w-full flex gap-2 items-center text-base font-medium p-4">
              <div className="size-14">
                <UserIcon user={user} />
              </div>
              <div className="items-center">
                <div className="text-lg">{user.first_name}</div>
                <div className="text-xs text-ellipsis">{user.email}</div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SideBar;
