import { useState } from "react";
import Button from "../Button/Button";
import BankTransferIcon from "../Icons/BankTransferIcon";
import PayPalcon from "../Icons/PayPalcon";
import Modal from "../modal";
import { useLocation } from "react-router-dom";
import { getQueriesMap } from "../../utils/queries";
import PaypalPayment from "./PaypalPayment";
import BankTransferPayment from "./BankTransferPayment";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import { toast } from "react-toastify";
import IUser from "../../interfaces/user";
import sendRequest from "../axios";
import { PuffLoader } from "react-spinners";

const MAX_WITHDRAW_AMOUNT = 20;

async function withdrawBalance(paymentMethod: string, callback: () => void) {
  if (paymentMethod != "bank_transfer" && paymentMethod != "paypal") return;
  // send withdrawal request 
  return sendRequest("POST", "/api/v1/earnings/withdraw", { "payment_method": paymentMethod })
    .then(res => {
      // console.log({ res });
      toast.success("withdrawal request created successfully");
      if (callback) callback();
    }).catch(err => {
      toast.error("an error occurred");
      console.log(err);
    })
}

function Payment() {
  const { user } = useDashboardContext();
  return (
    <div className="bg-white rounded-[10px]  w-full h-full">
      <div className="p-5">
        <div>Choose a Payment Method</div>
        <div className="md:flex my-5 gap-5 items-center w-full">
          <div className="md:basis-1/2 md:mt-0 mt-10  ">
            <PayPalPayment user={user} />
          </div>
          <div className="md:basis-1/2 md:mt-0 mt-10  ">
            <BankAccountPayment user={user} />
          </div>
        </div>
      </div>
    </div>
  );
}

function PayPalPayment({ user }: { user: IUser }) {
  const { balance, getEarnings } = useDashboardContext();
  const location = useLocation();
  const paypalmodalKey = "paypalwithdraw";
  const queries = getQueriesMap(location);
  const [openPaypalModal, setOpenPaypalModal] = useState(
    queries[paypalmodalKey] === "true"
  );
  const [withdrawalLoading, setWithdrawalLoading] = useState<boolean>(false);

  function showPaypalPaymentModal() {
    setOpenPaypalModal(true);
  }

  function closePaypalPaymentModal() {
    setOpenPaypalModal(false);
  }

  async function withdraw() {
    if (user.pay_pal_email) {
      if (balance < MAX_WITHDRAW_AMOUNT) {
        toast.info("Withdrawal threshold not reached");
        return;
      }
      // send withdrawal request
      try {
        setWithdrawalLoading(true);
        await withdrawBalance("paypal", getEarnings);
      } finally {
        setWithdrawalLoading(false);
      }
    } else {
      showPaypalPaymentModal();
    }
  }

  return (
    <div className="bg-white py-8 px-3 shadow-md rounded-[10px] flex-shrink-0 items-center text-center space-y-6">
      <div className="flex justify-center items-center my-6">
        <div className="w-[80px] h-[20px]">
          <PayPalcon />
        </div>
      </div>
      <div className="text-sm font-medium">PayPal</div>
      {user.pay_pal_email ? "" : <Modal
        openModal={showPaypalPaymentModal}
        closeModal={closePaypalPaymentModal}
        open={openPaypalModal}
        closeIconColor="black">
        <PaypalPayment callback={closePaypalPaymentModal} />
      </Modal>}
      <Button
        disabled={withdrawalLoading}
        className="mx-auto"
        onClick={withdraw}>
        {withdrawalLoading ? <PuffLoader size={30} color="white" /> : ""} {user.pay_pal_email ? "Withdraw Now" : "Add PayPal account"}
      </Button>
    </div>
  )
}


function BankAccountPayment({ user }: { user: IUser }) {
  const { balance, getEarnings } = useDashboardContext();
  const location = useLocation();
  const queries = getQueriesMap(location);
  const bankPaymentModalKey = "bankwithdraw";
  const [openBankPaymentModal, setOpenBankPaymentModal] = useState(
    queries[bankPaymentModalKey] === "true"
  );
  const [withdrawalLoading, setWithdrawalLoading] = useState<boolean>(false);

  function showBankPaymentModal() {
    setOpenBankPaymentModal(true);
  }

  function closeBankPaymentModal() {
    setOpenBankPaymentModal(false);
  }

  async function withdraw() {
    if (user.bank_account) {
      // console.log({ balance });
      if (balance < MAX_WITHDRAW_AMOUNT) {
        toast.info("minimum withdrawals is $50");
        return;
      }
      try {
        setWithdrawalLoading(true);
        await withdrawBalance("bank_transfer", getEarnings);
      } finally {
        setWithdrawalLoading(false);
      }
    } else {
      showBankPaymentModal()
    }
  }

  return (
    <div className="bg-white py-8 px-3 shadow-md rounded-[10px] flex-shrink-0 items-center text-center space-y-6">
      <div className="flex justify-center">
        <div className="w-[50px] h-[50px]">
          <BankTransferIcon />
        </div>
      </div>
      <div className="text-sm font-medium ">Bank Transfer</div>
      {user.bank_account ? "" : <Modal
        openModal={showBankPaymentModal}
        closeModal={closeBankPaymentModal}
        open={openBankPaymentModal}
        closeIconColor="black">
        <BankTransferPayment callback={closeBankPaymentModal} />
      </Modal>}
      <Button
        disabled={withdrawalLoading}
        className="mx-auto"
        onClick={withdraw}>
        {withdrawalLoading ? <PuffLoader /> : ""} {user.bank_account ? "Withdraw Now" : "Add Bank Account"}
      </Button>
    </div>
  )
}

export default Payment;
